import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Loading, Table, Td } from "styleguide";
import { clearArticleState, createArticle, editArticle, fetchArticle } from "../../actions/article";
import { fetchCategories } from "../../actions/category";
import { updateFile } from "../../actions/file";
import { createJournalist } from "../../actions/journalist";
import { clearNewspaperState, fetchNewspaper, fetchNewspapers } from "../../actions/newspaper";
import { fetchPlaylists, fetchSuggestedPlaylists } from "../../actions/playlist";
import { clearPronunciationState, createPronunciation, fetchPronunciations, editPronunciation, deletePronunciation, verifyPronunciation } from "../../actions/pronunciation";
import { fetchVoiceRequirements } from "../../actions/voicerequirements";
import { fetchSuggestedTags } from "../../actions/tag";
import { fetchTemplates } from "../../actions/template";
import { fetchUsers } from "../../actions/user";
import ArticleFormEditor from "../../components/ArticleFormEditor/ArticleFormEditor";
import ArticleFormNarrator from "../../components/ArticleFormNarrator";
import ArticleScript from "../../components/ArticleScript";
import PronunciationForm from "../../components/PronunciationForm";
import config from "../../config";
import { FETCH_SUGGESTED_TAGS_SUCCESS } from "../../constants/tag";
import { createDefaultArticleNestedProps, createDefaultArticle, getCustomTemplate } from "../../services/article";
import { requestAllPlaylists, requestTopPlaylistsWithSections } from "../../services/playlist";
import { requestNarrators } from "../../services/user";
import IArticle from "../../types/article";
import * as errors from "../../types/errors";
import IJournalist from "../../types/journalist";
import INewspaper from "../../types/newspaper";
import IPlaylist from "../../types/playlist";
import IPronunciation from "../../types/pronunciation";
import ITemplate from "../../types/template";
import IUser from "../../types/user";
import UserRoles from "../../types/user-roles";
import { ddmmyy, formatDate } from "../../utils/date";
import { correctValue } from "../../utils/values";
import ISearch from "../../types/search";
import { fetchArticleTemplates, deleteArticleTemplate } from "../../actions/articletemplates";
import IArticleTemplate from "../../types/article-template";
import ModalSaveArticleTemplate from "../../components/ModalSaveArticleTemplate";
import { Helmet } from "react-helmet";
import { fetchSections } from "../../actions/section";
import ISection from "../../types/section";
import SectionTypes from "src/types/section-types";
import { IVoiceRequirement } from "src/types/voice-requirements";
import { fetchPodcastPlatforms } from "src/actions/podcastplatforms";
import { IPodcastPlatform } from "src/types/podcast-platforms";

import { PageWrapper } from "./styles";
import FullScreenPreview from "src/components/FullScreenPreview";
import TemplateType from "src/types/template-types";
import { fetchELVoices } from "src/actions/elevenlabs";
import { IELVoice } from "src/types/eleven-labs";
import { countAllWordsInArticleScript } from "src/utils/count-words";
import ArticlePreviewText from "src/components/ArticlePreviewText";
import ArticleNarrationTypes from "src/types/article-narration-types";
import { getPronunciationsFromText } from "src/utils/pronunciation-utils";
import { replaceAndMergeTextWithPronunciation, shouldAddSpaceAfterOrBeforePron } from "src/components/ArticlePreviewText/utils";
import { getAudioDurationFromUrl } from "src/utils/file";
import toast from "react-hot-toast";

export const ALL_PRONUNCIATIONS_LIMIT = 100000000;

interface ILocalState {
    shouldFixForm: boolean;
    showTemplateSaveModal: boolean;
    templateArticle?: IArticle;
    selectedPlaylists: IPlaylist[];
    previewTextScreenStatus: boolean;
    wordsToHighlightInRed: string[];
    isTextEditorFullScreen: boolean;
}

interface IPropsFromState {
    article?: IArticle;
    articleTemplates?: IArticleTemplate[];
    error?: errors.HttpError;
    isLoading: boolean;
    user: IUser;
    newspapers: INewspaper[];
    playlists: IPlaylist[];
    sections: ISection[];
    journalists: IJournalist[];
    templates: ITemplate[];
    narrators: IUser[];
    pronunciations: IPronunciation[];
    hasChanged: boolean;
    search: ISearch;
    voiceRequirements: IVoiceRequirement[];
    podcastPlatforms: IPodcastPlatform[];
    elevenLabsVoices: IELVoice[];
}

interface IPropsFromDispatch {
    createArticle: typeof createArticle;
    clearArticleState: typeof clearArticleState;
    fetchArticleTemplates: typeof fetchArticleTemplates;
    deleteArticleTemplate: typeof deleteArticleTemplate;
    clearNewspaperState: typeof clearNewspaperState;
    clearPronunciationState: typeof clearPronunciationState;
    editArticle: typeof editArticle;
    fetchArticle: typeof fetchArticle;
    updateFile: typeof updateFile;
    fetchNewspapers: typeof fetchNewspapers;
    fetchNewspaper: typeof fetchNewspaper;
    fetchPlaylists: typeof fetchPlaylists;
    fetchSections: typeof fetchSections;
    fetchUsers: typeof fetchUsers;
    fetchPronunciations: typeof fetchPronunciations;
    createPronunciation: typeof createPronunciation;
    editPronunciation: typeof editPronunciation;
    verifyPronunciation: typeof verifyPronunciation;
    deletePronunciation: typeof deletePronunciation;
    createJournalist: typeof createJournalist;
    fetchTemplates: typeof fetchTemplates;
    fetchSuggestedPlaylists: typeof fetchSuggestedPlaylists;
    fetchSuggestedTags: typeof fetchSuggestedTags;
    fetchVoiceRequirements: typeof fetchVoiceRequirements;
    fetchPodcastPlatforms: typeof fetchPodcastPlatforms;
    fetchELVoices: typeof fetchELVoices;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>, ILocalState> {
    constructor(props: IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>) {
        super(props);

        this.state = {
            shouldFixForm: false,
            showTemplateSaveModal: false,
            templateArticle: undefined,
            selectedPlaylists: [],
            previewTextScreenStatus: false,
            wordsToHighlightInRed: [],
            isTextEditorFullScreen: false,
        };
    }

    public openOrClosePreviewText = (activeStatus: boolean) => {
        this.setState({ previewTextScreenStatus: activeStatus });
    };

    public getArticle = () => {
        const preArticle = this.props.article;
        const defaultArticle = createDefaultArticle();

        return preArticle ? preArticle : defaultArticle;
    };

    public getSuggestedTags = async (text: string): Promise<string[]> => {
        const action = await this.props.fetchSuggestedTags(text);

        // @ts-ignore
        const tags = action.type === FETCH_SUGGESTED_TAGS_SUCCESS ? action.tags.map((t) => t.tagName) : [];

        return Promise.resolve(tags);
    };

    public getNewspaperName = () => {
        const {
            article,
            newspapers,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article || !newspapers || !article.newspaperId) {
            return "";
        }

        const newspaperId = +article.newspaperId;

        if (!dynamic) {
            return article.newspaper ? article.newspaper.newspaperName : "";
        }

        const newspaper = newspapers && newspapers.find((n) => n.newspaperID === newspaperId);

        return newspaper ? newspaper.newspaperName : "";
    };

    public getNarratorName = () => {
        const {
            article,
            narrators,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article || !narrators) {
            return "";
        }

        if (!dynamic) {
            const reader = article.articleReader;

            return reader ? `${reader.userFirstName} ${reader.userLastName}` : "";
        }

        const narratorId = article.articleAudioRecorderID || 0;
        const narrator = narrators && narrators.find((n) => n.userId === +narratorId);

        return narrator ? `${narrator.userFirstName} ${narrator.userLastName}` : "";
    };

    public getJournalistsListAndNamesJoined = (): { journalists: IJournalist[]; text: string } => {
        const {
            article,
            journalists,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article || !journalists) {
            return { journalists: [], text: "" };
        }

        if (!dynamic) {
            const jours = article.journalists || [];
            const journalistsLength = jours?.length || 0;

            return {
                journalists: jours,
                text: jours
                    .map((j) => j.journalistName)
                    .reduce((joined, journalist, currentIndex) => {
                        if (journalistsLength === 1 || currentIndex === journalistsLength - 1) return `${joined}${journalist}`;

                        if (journalistsLength === 2) {
                            return `${joined}${journalist} and `;
                        }

                        if (journalistsLength > 2 && currentIndex === journalistsLength - 2) {
                            return `${joined}${journalist}, and `;
                        }

                        return `${joined}${journalist}, `;
                    }, ""),
            };
        }

        const ownJournalistsId = article.journalistsId ? article.journalistsId : [];
        const journalistsFiltered = (journalists && journalists.filter((j) => !!ownJournalistsId.find((o) => +o === j.journalistId))) || [];

        const journalistsLength = journalistsFiltered.length;

        return {
            journalists: journalistsFiltered,
            text: journalistsFiltered
                .map((j) => j.journalistName)
                .reduce((joined, journalist, currentIndex) => {
                    if (journalistsLength === 1 || currentIndex === journalistsLength - 1) return `${joined}${journalist}`;

                    if (journalistsLength === 2) {
                        return `${joined}${journalist} and `;
                    }

                    if (journalistsLength > 2 && currentIndex === journalistsLength - 2) {
                        return `${joined}${journalist}, and `;
                    }

                    return `${joined}${journalist}, `;
                }, ""),
        };
    };

    public getSpecificTemplate = (type: TemplateType): string => {
        const { article } = this.props;

        if (!article) {
            return "";
        }

        switch (type) {
            case TemplateType.INTRO:
                return article.intro ? article.intro.templateText : "";
            case TemplateType.OUTRO:
                return article.outro ? article.outro.templateText : "";
            default:
                return "";
        }
    };

    public getTextTemplate = (type: TemplateType): string => {
        const {
            article,
            templates,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article) {
            return "";
        }

        if (!dynamic) {
            return this.getSpecificTemplate(type);
        }

        const templateId = type === TemplateType.INTRO ? article.introId || 0 : article.outroId || 0;

        const template = templates && templates.find((t) => t.templateId === +templateId);

        return template ? template.templateText : "";
    };

    public getTemplate = (type: TemplateType, otherArticle?: IArticle, noManualStyling?: boolean) => {
        const article = otherArticle || this.props.article;

        if (!article) {
            return "";
        }

        let template = this.getTextTemplate(type);

        if (template === "") {
            if (type === TemplateType.INTRO) {
                if (article.manualIntro) {
                    if (!noManualStyling) {
                        template = getCustomTemplate(article.manualIntro, type);
                    } else {
                        template = article.manualIntro;
                    }
                } else {
                    template = "";
                }
            } else {
                if (article.manualOutro) {
                    if (!noManualStyling) {
                        template = getCustomTemplate(article.manualOutro, type);
                    } else {
                        template = article.manualOutro;
                    }
                } else {
                    template = "";
                }
            }
        }

        const title = article.altNameForNarration ? article.altNameForNarration : article.articleName;
        const section = article.articleSection || "";
        const subtitle = article.articleSubName || "";
        const newspaperName = this.getNewspaperName();
        const narratorName = this.getNarratorName();
        const date = formatDate(article.articleAddedDateTime, { enhancedFormatting: true });
        const viewDate = formatDate(article.articleViewDateTime, { enhancedFormatting: true });
        const publicationDate = formatDate(article.articleOriginalPublicationDateTime, { enhancedFormatting: true });

        const { text: journalistsName, journalists } = this.getJournalistsListAndNamesJoined();
        const journalistsLength = journalists.length;
        const journalistsWriteOrWrites = journalistsLength < 1 ? "" : journalistsLength > 1 ? "write" : "writes";

        return _.chain(template)
            .replace(/\n/g, "<br/>")
            .replace(/\{title\}/g, title)
            .replace(/\{section\}/g, section)
            .replace(/\{publisher\}/g, newspaperName)
            .replace(/\{narrator\}/g, narratorName)
            .replace(/\{journalists\}/g, journalistsName)
            .replace(/\{journalists_write\}/g, journalistsWriteOrWrites)
            .replace(/\{date\}/g, date)
            .replace(/\{view_date\}/g, viewDate)
            .replace(/\{publication_date\}/g, publicationDate)
            .replace(/\{subtitle\}/g, subtitle)
            .value();
    };

    public renderArticleInfo = () => {
        const { article } = this.props;

        if (!article || article.articleID === 0) {
            return null;
        }

        return (
            <Table title="Info">
                <tbody>
                    <tr>
                        <Td>Article Inserted At</Td>
                        <Td align="right">{article.articleAddedDateTime ? ddmmyy(new Date(article.articleAddedDateTime)) : "-"}</Td>
                    </tr>
                    <tr>
                        <Td>Article Updated At</Td>
                        <Td align="right">{article.updatedAt ? ddmmyy(new Date(article.updatedAt)) : "-"}</Td>
                    </tr>
                    <tr>
                        <Td>Article Inserted By</Td>
                        <Td align="right">{article.articleAddedBy && article.articleAddedBy.userLogin}</Td>
                    </tr>
                </tbody>
            </Table>
        );
    };

    public deleteArticleTemplate = (template: IArticleTemplate) => {
        this.props.deleteArticleTemplate(template);
    };

    public fetchPlaylists = (sectionId: number) => {
        if (sectionId === -3) {
            this.props.fetchPlaylists(requestAllPlaylists(), undefined, this.state.selectedPlaylists);
            return;
        }

        this.props.fetchPlaylists(requestTopPlaylistsWithSections(), sectionId, this.state.selectedPlaylists);
    };

    public fetchSuggestedPlaylists = (tags: string[]) => {
        this.props.fetchSuggestedPlaylists(tags, this.state.selectedPlaylists);
    };

    public setSelectedPlaylists = (playlistsFiltered: IPlaylist[], newPlaylistIds: string[], previousPlaylistIds: string[]) => {
        // Remove de-selected playlists from selected list
        const deselectedPlaylistIds = _.filter(previousPlaylistIds, (p) => !_.includes(newPlaylistIds, p));
        const selectedPlaylists = _.filter(this.state.selectedPlaylists, (p) => !_.includes(deselectedPlaylistIds, `${p.playlistId}`));

        // Get the newly selected playlists, excluding those that were selected previously
        const newPlaylists = _.filter(playlistsFiltered, (p) => _.includes(newPlaylistIds, `${p.playlistId}`));
        const newSelectedPlaylists = _.filter(
            newPlaylists,
            (p) =>
                !_.includes(
                    selectedPlaylists.map((sp) => sp.playlistId),
                    p.playlistId,
                ),
        );

        // Save them
        selectedPlaylists.push(...newSelectedPlaylists);
        this.setState({ selectedPlaylists });
    };

    public renderArticleForm = () => {
        const { user, playlists, sections, voiceRequirements, podcastPlatforms, elevenLabsVoices } = this.props;

        const article = this.getArticle();

        if (user.userRoleIdList === UserRoles.NARRATOR) {
            const initialValue = {
                ...article,
                error: this.props.error,
            };

            return (
                <Card>
                    <ArticleFormNarrator {...this.props} initialValues={initialValue} onSubmit={(a: any) => this.submitForm(a, { redirectPath: config.paths.dashboard })} />
                </Card>
            );
        }

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            const fakeSuggestedSectionId = -1;

            const sectionsWithAdditional = [
                { sectionId: -2, sectionName: "RECENTLY CREATED/EDITED" },
                {
                    sectionId: fakeSuggestedSectionId,
                    sectionName: "SUGGESTED (Ordered by most relevant)",
                },
                { sectionId: -3, sectionName: "ALL" },
                ...sections.filter((s) => !s.parentId),
            ];

            const articlePlaylists = article.playlists;
            const playlistsFiltered = articlePlaylists
                ? _.filter(
                      playlists,
                      (p) =>
                          !_.includes(
                              articlePlaylists.map((ap) => ap.playlistId),
                              p.playlistId,
                          ),
                  )
                : playlists;
            const playlistsOrdered = playlistsFiltered.concat(articlePlaylists ? articlePlaylists : []);

            const articleWithNestedProps = {
                ...article,
                ...createDefaultArticleNestedProps(article),
                filterPlaylistsBySectionId: undefined,
            };

            const initialValue = {
                ...articleWithNestedProps,
                error: this.props.error,
            };

            const articleFormProps = {
                ...this.props,
                article: articleWithNestedProps,
                createJournalist: this.submitCreateJournalist,
                initialValues: initialValue,
                onSubmit: this.submitForm,
                createArticleTemplate: this.createArticleTemplate,
                deleteArticleTemplate: this.deleteArticleTemplate,
                playlists: playlistsOrdered,
                sections: sectionsWithAdditional,
                sectionsRaw: sections,
                voiceRequirements,
                podcastPlatforms,
                elevenLabsVoices,
                fetchPlaylists: this.fetchPlaylists,
                suggestPlaylists: this.fetchSuggestedPlaylists,
                suggestTags: this.getSuggestedTags,
                setSelectedPlaylists: this.setSelectedPlaylists,

                openPreviewText: () => this.openOrClosePreviewText(true),
                wordsToHighlightInRed: this.state.wordsToHighlightInRed,
                isTextEditorFullScreen: this.state.isTextEditorFullScreen,
                onMinimizeTextEditor: this.onMinimizeTextEditor(),
            };

            return (
                <>
                    {/* @ts-ignore */}
                    <ArticleFormEditor {...articleFormProps} />
                </>
            );
        }

        return null;
    };

    public renderPronunciationForm = () => {
        const { user } = this.props;

        if (user.userRoleIdList !== UserRoles.EDITOR && user.userRoleIdList !== UserRoles.SUPER_USER) {
            return null;
        }

        const props = { onSubmit: this.submitPronunciationForm, small: true };

        return (
            <Card title="Pronunciation" className={css(styles.noPaddingBottom)}>
                <PronunciationForm {...props} />
            </Card>
        );
    };

    public renderTextInputCard = () => {
        const props = { onSubmit: this.submitPronunciationForm, small: true };

        return (
            <Card title="Pronunciation" className={css(styles.noPaddingBottom)}>
                <PronunciationForm {...props} />
            </Card>
        );
    };

    public renderArticleScript = () => {
        const {
            article,
            journalists,
            narrators,
            newspapers,
            pronunciations,
            templates,
            user: { userRoleIdList: userRole },
            history,
        } = this.props;

        if (userRole !== UserRoles.NARRATOR && userRole !== UserRoles.EDITOR && userRole !== UserRoles.SUPER_USER) {
            return null;
        }

        // const dynamic = userRole !== UserRoles.NARRATOR;
        const dynamic = false;

        return (
            <div>
                <div className={css(userRole !== UserRoles.NARRATOR && styles.bg)}>
                    <ArticleScript
                        print={false}
                        article={article}
                        newspapers={newspapers}
                        journalists={journalists}
                        narrators={narrators}
                        pronunciations={pronunciations}
                        templates={templates}
                        dynamic={dynamic}
                        user={this.props.user}
                        editPronunciation={this.props.editPronunciation}
                        verifyPronunciation={this.props.verifyPronunciation}
                        deletePronunciation={this.props.deletePronunciation}
                        fetchPronunciations={this.props.fetchPronunciations}
                        getTemplate={this.getTemplate}
                        search={this.props.search}
                        history={history}
                    />
                </div>
            </div>
        );
    };

    public changePageIfNeeded = (articleId: number, changePage: boolean = false, redirectPath?: string) => {
        const { history } = this.props;

        if (redirectPath) {
            return window.location.replace(redirectPath);
        }

        if (!changePage) {
            return window.location.replace(`${config.paths.article}/${articleId}`);
        }
        return history.push(config.paths.article);
    };

    public createArticleTemplate = (article: IArticle) => {
        this.setState({
            templateArticle: article,
            showTemplateSaveModal: true,
        });

        // this.props.createArticleTemplate(articleTemplate);
    };

    public convertToPlain = (html: string) => {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;

        return tempDivElement.textContent || tempDivElement.innerText || "";
    };

    public replacePronunciations = (articleText: string) => {
        const { pronunciations } = this.props;

        if (!pronunciations) {
            return articleText;
        }

        const prons = getPronunciationsFromText(articleText, pronunciations);
        const transformFn = (word: string, prevWord?: string, nextWord?: string, current?: { pron: IPronunciation; word: string }) => {
            const firstWordTrimmed = nextWord?.trim();

            const firstLetterOfNW = firstWordTrimmed?.[0];
            const lastLetterOfPW = prevWord && prevWord?.[prevWord?.length - 1];

            const shouldAddSpaceAfter = !firstLetterOfNW ? true : shouldAddSpaceAfterOrBeforePron(firstLetterOfNW);
            const shouldAddSpaceBefore = !lastLetterOfPW ? true : shouldAddSpaceAfterOrBeforePron(lastLetterOfPW);

            const wordToInsert = current?.pron?.aiPhonetic || word;

            const wordToReturn = `${shouldAddSpaceBefore ? " " : ""}${wordToInsert}${shouldAddSpaceAfter ? " " : ""}`;

            return { transformed: wordToReturn };
        };

        const textReplacedArr = replaceAndMergeTextWithPronunciation(articleText, prons, ["\n", "\n"], transformFn)
            .flat(Infinity)
            .map((word) => (typeof word !== "string" ? word.transformed : word));

        // joining and replacing multiple spaces with single space
        const textReplaced = textReplacedArr.join("").replace(/ {2,}/g, " ");
        return textReplaced;
    };

    public submitForm = async (article: (IArticle & { changePage: boolean }) | any, config?: { redirectPath?: string }) => {
        const { article: oldArticle } = this.props;
        const changePage = article.changePage;
        const action = oldArticle && oldArticle.articleID ? this.props.editArticle : this.props.createArticle;

        const sectionsIdCombined = [
            ...(article.sectionsId && Array.isArray(article.sectionsId) ? article.sectionsId : []),
            ...(article.sectionsIdx && Array.isArray(article.sectionsIdx) ? article.sectionsIdx : []),
        ];

        const articleWithWordCount: IArticle = {
            ...article,
            sectionsId: sectionsIdCombined,
            tagsId:
                Number(article.articleType) === 0
                    ? [
                          ...(article.tagsId || []).map((t: any) => ({ tagName: t.tagName, tagId: t.tagId, isDisplayTag: false })),
                          ...(article.displayTagsId || []).map((t: any) => ({ tagName: t.tagName, tagId: t.tagId, isDisplayTag: true })),
                      ]
                    : [],
            articleWordCount: countAllWordsInArticleScript(article),
        };

        if (article.articleNarrationType !== ArticleNarrationTypes.HUMAN) {
            if (article.articleAudioFileName && article.audioUrl) {
                try {
                    const audioDuration = await getAudioDurationFromUrl(article.audioUrl);
                    articleWithWordCount.articleAudioLength = `${audioDuration}`;
                } catch {
                    toast.error("Failed to get audio duration. Article can't be saved.");
                    return;
                }
            }
            if (article.articleExternalAudioFileName && article.externalAudioUrl) {
                try {
                    const externalAudioDuration = await getAudioDurationFromUrl(article.externalAudioUrl);
                    articleWithWordCount.articleExternalAudioLength = externalAudioDuration;
                } catch {
                    toast.error("Failed to get off-platform audio duration. Article can't be saved.");
                    return;
                }
            }
        }

        return (
            action(articleWithWordCount)
                // @ts-ignore
                .then((articleId) => {
                    if (_.isNumber(articleId) && articleId !== 0) {
                        this.changePageIfNeeded(articleId, changePage, config?.redirectPath);
                    }
                })
        );
    };

    public submitPronunciationForm = (pronunciation: IPronunciation | any) => {
        // @ts-ignore
        return this.props.createPronunciation(pronunciation).then(() => {
            this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        });
    };

    public submitCreateJournalist = () => {
        const { article } = this.props;

        if (!article || !article.journalistName) {
            return;
        }

        const journalist: IJournalist = {
            journalistId: 0,
            journalistName: article.journalistName,
            newspapersId: [`${article.newspaperId}`],
        };

        this.props
            .createJournalist(journalist, false)
            // @ts-ignore
            .then(() => this.props.fetchNewspaper(article.newspaperId));
    };

    public componentDidMount() {
        const { match, user } = this.props;

        this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        this.props.fetchTemplates();

        if (match.params.id) {
            this.props.fetchArticle(+match.params.id);
        }

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            this.props.fetchNewspapers();
            this.props.fetchArticleTemplates();
            this.props.fetchSections({
                filter: [
                    { name: "sectionIsPublished", value: 1 },
                    { name: "sectionType", value: SectionTypes.Regular },
                ],
            });
            this.fetchPlaylists(-2);
            this.props.fetchUsers(requestNarrators(), UserRoles.NARRATOR);
            this.props.fetchVoiceRequirements();
            this.props.fetchPodcastPlatforms();
            this.props.fetchELVoices();
        }

        this.maybeSetFixForm();
        window.onscroll = () => {
            this.maybeSetFixForm();
        };
    }

    public maybeSetFixForm() {
        if (window.scrollY >= 80 && window.innerWidth > 992) {
            if (!this.state.shouldFixForm) {
                this.setState({ shouldFixForm: true });
            }
        } else if (this.state.shouldFixForm) {
            this.setState({ shouldFixForm: false });
        }
    }

    public componentDidUpdate(prevProps: IPropsFromState) {
        const { user } = this.props;

        if (user.userRoleIdList !== UserRoles.EDITOR && user.userRoleIdList !== UserRoles.SUPER_USER) {
            return;
        }

        const newArticle = this.props.article ? this.props.article : { newspaperId: 0 };
        const prevArticle = prevProps.article ? prevProps.article : { newspaperId: 0 };

        if (newArticle.newspaperId !== prevArticle.newspaperId && !!newArticle.newspaperId && newArticle.newspaperId !== 0) {
            this.props.fetchNewspaper(newArticle.newspaperId);
        }
    }

    public isAiGenerationType = () => {
        const type = this.props.article?.articleNarrationType;

        return ([ArticleNarrationTypes.AI_GOLD, ArticleNarrationTypes.AI_SILVER] as any[]).includes(type);
    };

    public componentWillUnmount() {
        this.props.clearArticleState();
        this.props.clearNewspaperState();
        this.props.clearPronunciationState();
        window.onscroll = null;
    }

    public closeTemplateModal = () => {
        this.setState({ showTemplateSaveModal: false });
    };

    public setWordsToHighlightInRed = (words: string[], fullScreenStatus?: boolean) => {
        this.openOrClosePreviewText(false);
        this.setState((prev) => ({ ...prev, wordsToHighlightInRed: words, isTextEditorFullScreen: fullScreenStatus !== undefined ? fullScreenStatus : true }));
    };

    public onMinimizeTextEditor = () => {
        const shouldOpenTextPreview = this.state.isTextEditorFullScreen === true;

        return () => {
            this.setWordsToHighlightInRed([], false);

            if (shouldOpenTextPreview) {
                document.body.style.overflow = "hidden";
                this.openOrClosePreviewText(true);
            }
        };
    };

    public render() {
        const { isLoading, user } = this.props;
        const { showTemplateSaveModal, templateArticle } = this.state;

        if (user.userRoleIdList === UserRoles.NARRATOR) {
            return (
                <React.Fragment>
                    {isLoading && <Loading />}
                    <div className="col s12 m12 l6 noprint">{this.renderArticleForm()}</div>
                    <div className="col s12 m12 l6">{this.renderArticleScript()}</div>
                </React.Fragment>
            );
        }

        const title = `Article Editor | ${this.props.article && this.props.article.articleID > 0 ? this.props.article?.articleName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {isLoading && <Loading />}
                <ModalSaveArticleTemplate show={showTemplateSaveModal} article={templateArticle} close={this.closeTemplateModal} />
                {/* {this.renderPronunciationForm()} */}
                <PageWrapper>{this.renderArticleForm()}</PageWrapper>

                <FullScreenPreview
                    overflowHidden
                    activeStatus={this.state.previewTextScreenStatus}
                    openOrClosePreviewText={this.openOrClosePreviewText}
                    isTextEditorFullScreen={this.state.isTextEditorFullScreen}
                >
                    {/* <div>{this.renderArticleScript()}</div> */}
                    <ArticlePreviewText
                        getTemplate={this.getTemplate}
                        isAiGenerationType={this.isAiGenerationType()}
                        article={this.props.article}
                        pronunciations={this.props.pronunciations}
                        setWordsToHighlightInRed={this.setWordsToHighlightInRed}
                    />
                    {/* <div className="col s12 m12 noprint">{this.renderArticleInfo()}</div> */}
                </FullScreenPreview>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { ArticleForm },
        article: { article },
        newspaper: { newspaper },
        articleTemplate: { articleTemplates },
    } = state;

    const articleValues = ArticleForm ? ArticleForm.values : {};

    const journalists = newspaper && newspaper.journalists ? newspaper.journalists : [];

    const preArticle = correctValue("articleID", articleValues, article);
    const validatedArticle = preArticle;
    const hasChanged = ArticleForm && !ArticleForm.submitSucceeded ? !!ArticleForm.anyTouched : false;

    return {
        article: validatedArticle,
        articleTemplates,
        error: state.article.error,
        hasChanged,
        isLoading:
            state.article.isLoading ||
            state.file.isLoading ||
            state.newspaper.isLoading ||
            state.user.isLoading ||
            state.category.isLoading ||
            state.journalist.isLoading ||
            state.pronunciation.isLoading ||
            state.suggestion.isLoading ||
            state.section.isLoading ||
            state.tag.isLoading ||
            state.voiceRequirements.isLoading ||
            state.podcastPlatforms.isLoading ||
            state.elevenLabs.isLoading,
        journalists,
        narrators: state.user.narrators,
        newspapers: state.newspaper.newspapers,
        playlists: state.playlist.playlists,
        pronunciations: state.pronunciation.pronunciations,
        templates: state.template.templates,
        user: state.auth.user as IUser,
        search: state.search,
        sections: state.section.sections,
        voiceRequirements: state.voiceRequirements.voiceRequirements,
        podcastPlatforms: state.podcastPlatforms.platforms,
        elevenLabsVoices: state.elevenLabs.voices,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearArticleState,
            clearNewspaperState,
            clearPronunciationState,
            createArticle,
            createJournalist,
            createPronunciation,
            deletePronunciation,
            editPronunciation,
            verifyPronunciation,
            editArticle,
            fetchArticle,
            fetchArticleTemplates,
            deleteArticleTemplate,
            fetchCategories,
            fetchNewspaper,
            fetchNewspapers,
            fetchPlaylists,
            fetchSections,
            fetchPronunciations,
            fetchSuggestedPlaylists,
            fetchSuggestedTags,
            fetchTemplates,
            fetchUsers,
            updateFile,
            fetchVoiceRequirements,
            fetchPodcastPlatforms,
            fetchELVoices,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));

const styles = StyleSheet.create({
    bg: {
        backgroundColor: "#f4f5f7",
        paddingBottom: "50px !important",
    },
    articleScript: {
        position: "fixed",
        right: 0,
        top: 0,
    },
    noPaddingBottom: {
        paddingBottom: 0,
    },
});
