import React, { useEffect, useState } from "react";
// import ReactSelectionPopup from "react-selection-popup";
import IArticle from "src/types/article";
import IPronunciation from "src/types/pronunciation";
import { PreviewScreenChecklist, PreviewScreenSplit, PreviewScreenTextWrapper, PreviewText, PronunciationButtonPopup, TextPopupWrapper } from "./styles";
import { getPronunciationsFromText, getWarnWordFromText } from "src/utils/pronunciation-utils";
import { removeHtmlFromText } from "src/utils/string";
import { Modal } from "styleguide";
import PronunciationForm from "../PronunciationForm";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { IState } from "src/reducers";
import IUser from "src/types/user";
import { createPronunciation, editPronunciation, fetchPronunciations } from "src/actions/pronunciation";
import { bindActionCreators, Dispatch } from "redux";
import usePronunciationChecklist from "./usePronunciationChecklist";
import { Input } from "src/styles/Input";
import toast from "react-hot-toast";
import { highlightWords, isAiPhonetic, replaceAndMergeTextWithPronunciation, shouldAddSpaceAfterOrBeforePron, shouldAddSpaceBeforePronForSpecialCases } from "./utils";
import TemplateType from "src/types/template-types";
import { countAllWordsInArticleScript } from "src/utils/count-words";

interface IProps {
    article?: IArticle;
    pronunciations?: IPronunciation[];
    isAiGenerationType?: boolean;
    getTemplate: (type: TemplateType, otherArticle?: IArticle, noManualStyling?: boolean) => string;
    setWordsToHighlightInRed: (words: string[]) => void;

    openLinkOnClickAndHidePopup?: boolean;
    hideWordPopups?: boolean;
    hideWarningWords?: boolean;
    singleColoredProns?: boolean;
    nonScrollablePage?: boolean;
    title?: string;
    excludeAiPronunciations?: boolean;
    showPhoneticsOnHover?: boolean;
}

interface IPropsFromDispatch {
    createPronunciationFn: typeof createPronunciation;
    editPronunciationFn: typeof editPronunciation;
    fetchPronunciationsFn: typeof fetchPronunciations;
}

interface IPropsFromState {
    user?: IUser;
}

// using this for hiding the pronunciation button popup
const PRONUNCIATION_BUTTON_CLASS_NAME = "preview-text-pronunciation-button";
// using this for hiding the pronunciation button popup
const PRONUNCIATION_BUTTON_POPUP_CLASS_NAME = "preview-text-pronunciation-button-popup";

const TEXT_SELECTED_POPUP_CLASS_NAME = "preview-text-selected-popup";

const PronunciationButton = ({
    pronunciation,
    word,
    nextWord,
    prevWord,
    onClick,
    singleColoredProns,
    underlined,
    showPhoneticsOnHover,
}: {
    pronunciation: IPronunciation;
    word: string;
    nextWord?: string;
    prevWord?: string;
    onClick: (pronunciation: IPronunciation, position: { x: number; y: number }) => void;
    underlined: boolean;
    singleColoredProns: boolean;
    showPhoneticsOnHover: boolean;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const setHoverToTrue = () => {
        if (showPhoneticsOnHover) {
            setIsHovered(true);
        }
    };

    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const rect = e.currentTarget.getBoundingClientRect();

        onClick(pronunciation, { x: rect.x, y: rect.y });
    };

    const pronColor = isAiPhonetic(pronunciation) && !singleColoredProns ? "#6554C0" : "#008da6";

    const nextWordTrimmed = nextWord?.trim();

    const firstLetterOfNW = nextWordTrimmed?.[0];
    const lastLetterOfPW = prevWord && prevWord?.[prevWord?.length - 1];

    let shouldAddSpaceAfter = !firstLetterOfNW ? true : shouldAddSpaceAfterOrBeforePron(firstLetterOfNW);
    let shouldAddSpaceBefore = !lastLetterOfPW ? true : shouldAddSpaceAfterOrBeforePron(lastLetterOfPW) && shouldAddSpaceBeforePronForSpecialCases(prevWord);

    if (lastLetterOfPW === "(") shouldAddSpaceBefore = false;
    if (firstLetterOfNW === ")") shouldAddSpaceAfter = false;

    // space before and after the word is important because\
    // all the text is split and trimmed in multiple parts
    return (
        <span
            style={{ color: pronColor, ...(underlined ? { textDecoration: "underline" } : {}), ...(showPhoneticsOnHover ? { position: "relative" } : {}) }}
            className={PRONUNCIATION_BUTTON_CLASS_NAME}
            onClick={onButtonClick}
            onMouseEnter={setHoverToTrue}
            onMouseLeave={() => setIsHovered(false)}
        >
            {showPhoneticsOnHover && (
                <span
                    style={{
                        visibility: isHovered ? "visible" : "hidden",
                        opacity: isHovered ? "1" : "0",
                        position: "absolute",
                        bottom: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        padding: "10px",
                        borderRadius: "5px",
                        color: "#fff",
                        background: "#121212",
                        width: "max-content",
                        zIndex: 1,
                        fontSize: "15px",
                        transition: "0.2s ease all",
                    }}
                >
                    {pronunciation.pronunciationComment || pronunciation.aiPhonetic || "-"}
                </span>
            )}
            <span>
                {`${shouldAddSpaceBefore ? " " : ""}`}
                {word}
                {`${shouldAddSpaceAfter ? " " : ""}`}
            </span>
        </span>
    );
};

const WarningWord = ({ word, color, onClick }: { word: string; color?: string; onClick: (word: string) => void }) => {
    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        onClick(word);
    };
    return (
        <span style={{ color: color || "#BF2600" }} onClick={onButtonClick}>
            {word}
        </span>
    );
};

const ArticlePreviewText = ({
    pronunciations,
    article,
    user,
    isAiGenerationType,
    createPronunciationFn,
    editPronunciationFn,
    fetchPronunciationsFn,
    getTemplate,
    setWordsToHighlightInRed,

    hideWarningWords,
    singleColoredProns,
    hideWordPopups,
    openLinkOnClickAndHidePopup,
    nonScrollablePage,
    title,
    excludeAiPronunciations,
    showPhoneticsOnHover,
}: IProps & IPropsFromState & IPropsFromDispatch) => {
    const [prons, setProns] = useState<{ pron: IPronunciation; word: string }[]>([]);
    const [, setWarnWords] = useState<string[]>([]);

    const [selectedText, setSelectedText] = useState("");

    const [text, setText] = useState("");

    const [mergedText, setMergedText] = useState<any[]>([]);

    const [mergedTextWithWarnWords, setMergedTextWithWarnWords] = useState<any[]>([]);

    const [focusedPronunciationButton, setFocusedPronunciationButton] = useState<{ pronunciation: IPronunciation; position: { x: number; y: number } } | null>(null);

    const [modalParams, setModalParams] = useState<{ pronunciation?: IPronunciation; initialValues: IPronunciation } | null>(null);

    const [showModal, setShowModal] = useState(false);

    const [isPronsLoading, setIsPronsLoading] = useState(false);

    const [popupRects, setPopupRects] = useState<DOMRect>();

    const onCreatePronunciation = (callback?: () => void) => {
        setIsPronsLoading(true);
        (fetchPronunciationsFn as any)({ limit: 1000000000000 }).finally(() => setIsPronsLoading(false));

        if (callback) {
            callback();
        }
    };

    const {
        getChecklist,
        addToChecklist,
        playPron,
        changePronAiPhonetic,
        saveAiPhonetic,
        updatePron,
        removePron: removePronFromChecklist,
        addTextToChecklist,
        getTextChecklist,
        changeTextPronAiPhonetic,
        playTextPron,
        removeTextPronFromChecklist,
        saveTextPronAiPhonetic,
    } = usePronunciationChecklist({
        editPronunciation: editPronunciationFn,
        createPronunciation: createPronunciationFn,
        onCreatePronunciation,
        voiceId: article?.aiVoiceId,
        modelId: article?.aiModelId,
    });
    const pronsChecklist = getChecklist();
    const textChecklist = getTextChecklist();

    const onPlayChecklistPron = (pronId: number) => (e: any) => {
        e.preventDefault();

        playPron(pronId);
    };

    const onPlayTextChecklistPron = (pronId: string) => (e: any) => {
        e.preventDefault();

        playTextPron(pronId);
    };

    const onRemoveChecklistPron = (pronId: number) => (e: any) => {
        e.preventDefault();

        removePronFromChecklist(pronId);
    };

    const onRemoveTextChecklistPron = (pronId: string) => (e: any) => {
        e.preventDefault();

        removeTextPronFromChecklist(pronId);
    };

    const onSaveChecklistPron = (pronId: number) => (e: any) => {
        e.preventDefault();

        saveAiPhonetic(pronId);
    };

    const onSaveTextChecklistPron = (pronId: string) => (e: any) => {
        e.preventDefault();

        saveTextPronAiPhonetic(pronId);
    };

    const onAddTextToChecklist = (e: any) => {
        e.preventDefault();

        if (!article?.aiVoiceId || !article?.aiModelId) {
            // deselect the selected text onClick
            onTextDeSelect();

            toast.error("Please choose an AI voice/model before adding to checklist.");
            return;
        }

        addTextToChecklist(selectedText);

        // deselect the selected text onClick
        onTextDeSelect();
    };

    const onChecklistPronEdit = (pronId: number) => (e: any) => {
        e.preventDefault();

        const pron = pronsChecklist.find((p) => p.originalPron.pronunciationId === pronId);

        if (pron) {
            setFocusedPronunciationButton({ position: { x: 0, y: 0 }, pronunciation: pron.originalPron });
            setShowModal(true);
        }
    };

    const onChangeTextPronAiPhonetic = (pronId: string) => (e: any) => {
        e.preventDefault();

        const value = e.target.value || "";

        changeTextPronAiPhonetic(pronId, value);
    };
    const onChangeChecklistAiInput = (pronId: number) => (e: any) => {
        e.preventDefault();

        const value = e.target.value || "";

        changePronAiPhonetic(pronId, value);
    };

    const onOpenUrl = (e: any) => {
        e.preventDefault();

        const url = focusedPronunciationButton?.pronunciation?.pronunciationLink;

        if (url) {
            window.open(url);
        }

        deFocusPronunciationButton();
    };

    const deFocusPronunciationButton = () => {
        setFocusedPronunciationButton(null);
    };

    const onAddPronToChecklist = (e: any) => {
        e.preventDefault();

        if (!article?.aiVoiceId || !article?.aiModelId) {
            toast.error("Please choose an AI voice/model before adding to checklist.");
            return;
        }

        const pronToAdd = focusedPronunciationButton?.pronunciation;

        if (pronToAdd) {
            addToChecklist(pronToAdd);
        }

        deFocusPronunciationButton();
    };

    const onOpenPronunciationModel = (e: any) => {
        e.preventDefault();

        setShowModal(true);
    };

    const onCloseModal = () => {
        setShowModal(false);
    };

    const onPopupTextSelected = (text: string) => {
        setSelectedText(text);
    };

    const onPopupClosed = () => {
        setSelectedText("");
    };

    const onPronunciationButtonClick = (pronunciation: IPronunciation, position: { x: number; y: number }) => {
        if (!openLinkOnClickAndHidePopup) {
            // to prevent the clicking of button when selecting the text
            if (isTextSelected()) return;

            setFocusedPronunciationButton({ pronunciation, position });
        } else {
            if (pronunciation.pronunciationLink) {
                window.open(pronunciation.pronunciationLink, "blank");
            }
        }
    };

    const submitPronunciationForm = (pronunciation: IPronunciation | any) => {
        const focusedPronId = modalParams?.pronunciation?.pronunciationId;

        if (!!focusedPronId && focusedPronId === pronunciation?.pronunciationId) {
            (editPronunciationFn as any)(pronunciation, user, undefined, undefined, { hideToast: true })
                .then(() => {
                    onCloseModal();
                    toast.success("Pronunciation Edited.");
                })
                .catch(() => {
                    toast.error("Failed to edit pronunciation.");
                });
        } else {
            (createPronunciationFn as any)(pronunciation)
                .then(() => {
                    onCreatePronunciation();
                    onCloseModal();

                    toast.success("Pronunciation Created.");
                })
                .catch(() => {
                    toast.error("Failed to create pronunciation.");
                });
        }
    };

    const highlighTextInTextEditor = (text: string) => {
        setWordsToHighlightInRed([text]);
    };

    const onWarningWordClick = (word: string) => {
        // to prevent the clicking of button when selecting the text
        if (isTextSelected()) return;

        highlighTextInTextEditor(word);
    };

    const onClickTeButtonAfterTextSelection = () => {
        if (selectedText.trim().length) {
            highlighTextInTextEditor(selectedText);
            onTextDeSelect();
        }
    };

    const onClickTeButton = () => {
        if (!!focusedPronunciationButton?.pronunciation?.pronunciationName) {
            highlighTextInTextEditor(focusedPronunciationButton.pronunciation.pronunciationName);
            deFocusPronunciationButton();
        }
    };

    const onTextSelect = () => {
        if (!hideWordPopups) {
            const txt = window.getSelection()?.toString();
            const rects = window.getSelection()?.getRangeAt(0)?.getBoundingClientRect();

            if (txt?.trim()?.length && !!rects && txt !== selectedText) {
                setPopupRects(rects);
                onPopupTextSelected(txt);
            }
        }
    };

    const onTextDeSelect = (e?: any) => {
        // remove the selection
        window.getSelection()?.removeAllRanges();

        // close the popup
        setPopupRects(undefined);
        onPopupClosed();
    };

    const isTextSelected = () => {
        const txt = window.getSelection()?.toString();
        return !!txt?.trim()?.length;
    };

    useEffect(() => {
        const popupDeSelectFn = (e: any) => {
            if (!e?.target?.classList?.contains(TEXT_SELECTED_POPUP_CLASS_NAME) && !e?.target?.parentElement?.classList?.contains(TEXT_SELECTED_POPUP_CLASS_NAME)) {
                onTextDeSelect();
            }
        };

        document.body.addEventListener("mousedown", popupDeSelectFn);

        return () => document.body.removeEventListener("mousedown", popupDeSelectFn);
    }, []);

    useEffect(() => {
        const introText = removeHtmlFromText(getTemplate(TemplateType.INTRO, article, true).replaceAll("<br/>", "\n")).trim();
        const outroText = removeHtmlFromText(getTemplate(TemplateType.OUTRO, article, true).replaceAll("<br/>", "\n")).trim();

        const articleText = removeHtmlFromText(article?.articleText);

        const finalText = `_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _\n${introText}\n_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _\n${articleText}\n_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _\n${outroText}\n_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _`;

        let pronsFound = getPronunciationsFromText(
            finalText,
            pronunciations?.sort((a, b) => b.pronunciationName.length - a.pronunciationName.length),
        );

        if (excludeAiPronunciations) {
            pronsFound = pronsFound.filter((pron) => {
                if (!pron.pron.pronunciationComment?.trim().length && !!pron.pron.aiPhonetic?.trim().length) return false;

                return true;
            });
        }

        // console.log(pronsFound);
        const warningWords = getWarnWordFromText(finalText);
        // console.log(pronsFound);
        setProns(pronsFound);
        setWarnWords(warningWords);
        setText(finalText);
    }, [article?.articleText, article?.intro, article?.outro, article?.introId, article?.outroId, article?.manualIntro, article?.manualOutro, pronunciations]);

    useEffect(() => {
        /*
            This useEffect is being used to hide the pronunciation popup when click outside
        */

        function handleClickOutside(e: any) {
            const classList = Array.from(e.target?.classList || []);
            const parentClassList = Array.from(e.target?.parentElement?.classList || []);
            if (
                !classList.includes(PRONUNCIATION_BUTTON_POPUP_CLASS_NAME) &&
                !classList.includes(PRONUNCIATION_BUTTON_CLASS_NAME) &&
                !parentClassList.includes(PRONUNCIATION_BUTTON_POPUP_CLASS_NAME)
            ) {
                setFocusedPronunciationButton(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // It's 2d Array with Paragraphs and Words
        const textMergedWithProns = replaceAndMergeTextWithPronunciation(text, prons, [<br />, <br />], (word, prevWord, nextWord, current) => (
            <PronunciationButton
                word={word}
                prevWord={prevWord}
                nextWord={nextWord}
                pronunciation={current.pron}
                onClick={onPronunciationButtonClick}
                underlined={!!openLinkOnClickAndHidePopup && !!current.pron.pronunciationLink}
                singleColoredProns={!!singleColoredProns}
                showPhoneticsOnHover={!!showPhoneticsOnHover}
            />
        ));

        setMergedText(textMergedWithProns.flat(Infinity));
    }, [text, prons]);

    useEffect(() => {
        if (mergedText.length) {
            if (!hideWarningWords) {
                const textMergedWithWarnings = highlightWords(mergedText, (word, color) => <WarningWord onClick={onWarningWordClick} word={word} color={color} />, isAiGenerationType);

                setMergedTextWithWarnWords(textMergedWithWarnings);
            } else {
                setMergedTextWithWarnWords(mergedText);
            }
        }
    }, [mergedText, isAiGenerationType]);

    useEffect(() => {
        // updating prons in the checklist, if any of the prons is updated outside the checklist

        if (!isPronsLoading) {
            pronsChecklist.forEach((pron) => {
                const origPron = prons.find((orig) => orig.pron.pronunciationId === pron.originalPron.pronunciationId);

                if (origPron) {
                    if (origPron.pron.aiPhonetic !== pron.originalPron.aiPhonetic || origPron.pron.pronunciationName !== pron.originalPron.pronunciationName) {
                        updatePron(origPron.pron);
                    }
                } else {
                    // removing because a pronunciation can't be in the checklist if it's not in the article text.
                    removePronFromChecklist(pron.originalPron.pronunciationId, true);
                }
            });
        }
    }, [prons, pronsChecklist]);

    useEffect(() => {
        if (showModal) {
            const newModalParams: any = {
                initialValues: { pronunciationName: selectedText.trim() },
            };

            if (!!focusedPronunciationButton) {
                const pron = focusedPronunciationButton.pronunciation;
                newModalParams.initialValues = { ...pron };
                newModalParams.pronunciation = pron;
            }

            setModalParams(newModalParams);

            // de-select the selected on click Add
            onTextDeSelect();
        } else {
            setModalParams(null);
        }
    }, [showModal]);

    const canOpenUrl = !!focusedPronunciationButton?.pronunciation?.pronunciationLink;
    const wordCount = countAllWordsInArticleScript(article);

    return (
        <>
            {showModal && (
                <Modal title={`${!!modalParams?.pronunciation ? "Edit" : "Add"} Pronunciation`} className={css(styles.modal)} close={onCloseModal}>
                    <PronunciationForm {...{ ...modalParams, onSubmit: submitPronunciationForm }} />
                </Modal>
            )}

            {focusedPronunciationButton !== null && (
                <PronunciationButtonPopup className={PRONUNCIATION_BUTTON_POPUP_CLASS_NAME} position={focusedPronunciationButton.position}>
                    {isAiGenerationType && <button onClick={onAddPronToChecklist}>CHECK</button>}
                    <button onClick={onOpenPronunciationModel}>EDIT</button>
                    <button disabled={!canOpenUrl} onClick={onOpenUrl}>
                        URL
                    </button>
                    <button onClick={onClickTeButton}>TE</button>
                </PronunciationButtonPopup>
            )}

            {popupRects && (
                <TextPopupWrapper className={TEXT_SELECTED_POPUP_CLASS_NAME} style={{ position: "fixed", top: `${popupRects.top - 38}px`, left: `${popupRects.left}px` }}>
                    {isAiGenerationType && <button onClick={onAddTextToChecklist}>CHECK</button>}
                    <button onClick={onOpenPronunciationModel}>ADD</button>
                    <button onClick={onClickTeButtonAfterTextSelection}>TE</button>
                </TextPopupWrapper>
            )}

            <PreviewScreenSplit isPageNonScrollable={!!nonScrollablePage}>
                <PreviewScreenTextWrapper isPageNonScrollable={!!nonScrollablePage}>
                    <p style={{ fontSize: nonScrollablePage ? "16px" : "18px" }}>Word count: {wordCount} </p>
                    {!!title && <h1 style={{ fontSize: "26px", fontWeight: "500" }}>{title}</h1>}
                    <PreviewText isPageNonScrollable={!!nonScrollablePage} onMouseUp={onTextSelect} className="preview-text-selection" data-meta={JSON.stringify({ name: "preview-text-selection" })}>
                        {mergedTextWithWarnWords}
                    </PreviewText>
                </PreviewScreenTextWrapper>

                {isAiGenerationType && (
                    <PreviewScreenChecklist>
                        <h2>TEXT CHECKLIST</h2>
                        {textChecklist.map((item) => {
                            const pronId = item.id;
                            return (
                                <React.Fragment key={pronId}>
                                    <p>{item.pronunciationName}</p>
                                    <div>
                                        <button disabled={!item.canSave} onClick={onSaveTextChecklistPron(pronId)}>
                                            CREATE
                                        </button>

                                        <Input value={item.aiPhonetic} onChange={onChangeTextPronAiPhonetic(pronId)} />
                                        <button disabled={!item.canPlay} onClick={onPlayTextChecklistPron(pronId)}>
                                            PLAY
                                        </button>
                                        <button onClick={onRemoveTextChecklistPron(pronId)}>CANCEL</button>
                                    </div>
                                </React.Fragment>
                            );
                        })}

                        <h2>PRONUNCIATION CHECKLIST</h2>
                        {pronsChecklist.map((item) => {
                            const pronId = item.pron.pronunciationId;
                            return (
                                <React.Fragment key={pronId}>
                                    <p>{item.pron.pronunciationName}</p>
                                    <div>
                                        {item.canRemove ? (
                                            <button onClick={onRemoveChecklistPron(pronId)}>DONE</button>
                                        ) : (
                                            <button disabled={!item.canSave} onClick={onSaveChecklistPron(pronId)}>
                                                UPDATE
                                            </button>
                                        )}
                                        <Input value={item.pron.aiPhonetic} onChange={onChangeChecklistAiInput(pronId)} />
                                        <button disabled={!item.canPlay} onClick={onPlayChecklistPron(pronId)}>
                                            PLAY
                                        </button>
                                        <button onClick={onChecklistPronEdit(pronId)}>EDIT</button>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </PreviewScreenChecklist>
                )}
            </PreviewScreenSplit>
        </>
    );
};

const styles = StyleSheet.create({
    modal: {
        zIndex: 100000,
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            createPronunciationFn: createPronunciation,
            editPronunciationFn: editPronunciation,
            fetchPronunciationsFn: fetchPronunciations,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePreviewText);
